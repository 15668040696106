import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------
const firebaseConfig = {
  apiKey: "AIzaSyD7k69MOde-L7JjakU9YvqhenqjklLJp1c",
  authDomain: "bluemoon-36cf8.firebaseapp.com",
  projectId: "bluemoon-36cf8",
  storageBucket: "bluemoon-36cf8.appspot.com",
  messagingSenderId: "674987691997",
  appId: "1:674987691997:web:e5fe4ff6f1837fa4807034",
  measurementId: "G-VDCZD6R5JB"
};

export default function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
