import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = '/logo/logo_single.png';

  const logo = (
    <Box sx={{ width: 150, height: 40, ...sx }}>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" viewBox="0 0 2088 627" enableBackground="new 0 0 2088 627" xmlSpace="preserve">
<path fill="#FFFFFF" opacity="0.000000" stroke="none" 
	d="
M1324.000000,628.000000 
	C882.707214,628.000000 441.914368,628.000000 1.060776,628.000000 
	C1.060776,419.059753 1.060776,210.119461 1.060776,1.089590 
	C696.876282,1.089590 1392.752563,1.089590 2088.814453,1.089590 
	C2088.814453,209.999878 2088.814453,418.999908 2088.814453,628.000000 
	C1834.119141,628.000000 1579.309570,628.000000 1324.000000,628.000000 
M1313.000000,98.508240 
	C1313.000000,94.077705 1313.000000,89.647171 1313.000000,85.000008 
	C1302.214966,85.000008 1292.066284,85.140640 1281.925293,84.920403 
	C1278.572388,84.847603 1276.941895,86.238426 1275.435059,88.986626 
	C1271.464233,96.228508 1267.203613,103.311707 1263.044556,110.450104 
	C1253.341675,127.103752 1243.649048,143.763306 1233.918091,160.400482 
	C1225.326782,175.089188 1216.675903,189.743073 1208.075684,204.426498 
	C1197.352173,222.734818 1186.669922,241.067337 1175.937012,259.370209 
	C1170.542358,268.569885 1165.075439,277.727142 1159.647339,286.893097 
	C1149.354980,269.418152 1139.215820,252.081497 1128.955200,234.817017 
	C1118.855591,217.823395 1108.577759,200.935669 1098.478882,183.941528 
	C1087.387939,165.277878 1076.448975,146.523819 1065.373657,127.850754 
	C1057.356323,114.333313 1049.244385,100.871773 1041.103394,87.428429 
	C1040.507324,86.444145 1039.342651,85.130066 1038.425903,85.116859 
	C1027.341919,84.957054 1016.254761,85.013313 1005.352783,85.013313 
	C1005.352783,181.738220 1005.352783,277.786438 1005.352783,373.619568 
	C1019.105286,373.619568 1032.502686,373.619568 1046.160034,373.619568 
	C1046.160034,305.348999 1046.160034,237.458511 1046.160034,168.113708 
	C1080.749756,225.760315 1114.626099,282.217834 1148.696655,338.999237 
	C1154.336670,338.999237 1160.343018,339.239197 1166.307373,338.844269 
	C1167.967651,338.734314 1170.090088,337.096924 1171.007324,335.565460 
	C1183.161377,315.273773 1195.091553,294.848358 1207.169556,274.510834 
	C1225.781982,243.170746 1244.449219,211.863190 1263.137451,180.568176 
	C1265.650024,176.360626 1268.420044,172.306885 1271.070923,168.181931 
	C1271.423584,168.323257 1271.776123,168.464584 1272.128662,168.605911 
	C1272.128662,236.969635 1272.128662,305.333374 1272.128662,373.688934 
	C1285.964478,373.688934 1299.359741,373.688934 1313.000000,373.688934 
	C1313.000000,282.087036 1313.000000,190.795975 1313.000000,98.508240 
M62.500000,85.000000 
	C48.073723,85.000000 33.647449,85.000000 19.344770,85.000000 
	C19.344770,181.700714 19.344770,277.759644 19.344770,374.000000 
	C21.508398,374.000000 23.330133,374.000000 25.151867,374.000000 
	C71.982056,374.000000 118.812294,374.030396 165.642365,373.956390 
	C170.734955,373.948334 175.822937,373.259308 180.919357,373.021332 
	C195.017441,372.363098 208.620758,369.315735 221.603424,363.924133 
	C235.325302,358.225647 247.704468,350.612701 256.077881,337.686707 
	C268.679169,318.234100 270.353943,297.188843 265.586548,275.158997 
	C262.871490,262.612854 256.884552,251.528351 247.857010,242.671494 
	C239.086258,234.066559 228.469299,227.809464 216.124908,224.673767 
	C216.689209,223.970474 216.931122,223.499313 217.313980,223.224365 
	C218.369171,222.466522 219.451721,221.735504 220.579041,221.091156 
	C231.871078,214.636810 240.213547,205.179840 245.536591,193.632782 
	C252.713821,178.063522 254.566147,161.662750 251.136490,144.526184 
	C246.679184,122.254936 233.421753,107.110558 213.766510,97.521141 
	C194.890060,88.311707 174.389191,85.152290 153.493744,85.040825 
	C123.496605,84.880798 93.497986,85.000000 62.500000,85.000000 
M769.465820,377.000000 
	C802.391235,376.138855 831.598633,366.573883 854.238464,341.029358 
	C846.474060,332.053406 838.898926,323.296234 831.994751,315.314697 
	C823.894958,321.220947 817.050110,327.630554 809.033997,331.774078 
	C789.950745,341.638214 769.527954,343.458099 748.502136,339.031128 
	C723.233398,333.710785 704.586975,320.069275 694.526917,295.899719 
	C692.228333,290.377289 691.141296,284.350525 689.300476,277.864532 
	C749.272339,277.864532 808.029846,277.864532 867.000427,277.864532 
	C867.000427,269.938416 867.634216,262.371460 866.842834,254.956558 
	C865.812317,245.300568 864.507507,235.515686 861.801819,226.228760 
	C855.718384,205.348099 844.146912,187.753342 826.782776,174.409378 
	C809.700623,161.282150 789.932495,154.757736 768.690308,153.134705 
	C753.739563,151.992371 738.850952,153.514465 724.416626,158.014771 
	C698.626587,166.055557 678.270081,181.213699 664.761169,204.771515 
	C652.066467,226.909378 647.360107,250.720886 649.809387,276.288971 
	C651.372192,292.603668 655.668884,307.747009 663.505920,322.011993 
	C673.114807,339.502014 687.037354,352.559967 704.444153,362.101471 
	C724.411316,373.046326 746.004028,376.903229 769.465820,377.000000 
M1578.944702,301.258942 
	C1580.629150,292.670380 1583.250000,284.140381 1583.791504,275.480347 
	C1584.744995,260.235718 1583.506470,245.152573 1579.245483,230.177155 
	C1572.610718,206.859329 1559.256226,188.457245 1540.270874,174.198380 
	C1522.727783,161.022644 1502.168579,154.932220 1480.522827,153.146225 
	C1467.563843,152.076965 1454.541626,153.015488 1441.759155,155.991196 
	C1414.026611,162.447235 1391.141846,176.337738 1375.024536,200.165573 
	C1356.649170,227.331482 1352.210571,257.611725 1358.326904,289.123413 
	C1362.829834,312.321838 1373.730835,332.542969 1391.953003,348.663513 
	C1412.962402,367.249817 1437.698486,375.434540 1465.004761,376.823700 
	C1473.957031,377.279144 1483.072876,376.110107 1492.011597,374.917084 
	C1513.141235,372.097015 1531.857910,363.362610 1547.870605,349.470093 
	C1562.585693,336.703339 1572.642822,320.599670 1578.944702,301.258942 
M1601.992310,254.265884 
	C1601.661743,255.857956 1600.968262,257.471191 1601.059692,259.038635 
	C1601.624878,268.722168 1601.405273,278.582184 1603.242310,288.034576 
	C1607.556152,310.231384 1617.479370,329.566010 1633.951538,345.645599 
	C1655.164062,366.352386 1680.998535,375.410858 1709.760498,376.753510 
	C1720.786743,377.268219 1732.114746,375.905029 1743.005249,373.825562 
	C1769.695801,368.729156 1791.658691,355.052979 1807.817261,333.262146 
	C1827.467285,306.762817 1832.481445,276.268768 1827.753052,244.351868 
	C1824.291748,220.988220 1813.951660,200.668198 1796.592896,183.878281 
	C1776.366455,164.314667 1751.851685,155.435379 1724.603882,153.150192 
	C1710.673584,151.981857 1696.758301,153.397522 1683.068848,156.934647 
	C1657.845581,163.451920 1637.096436,176.585251 1621.813843,197.774261 
	C1609.881348,214.318680 1603.106445,232.853119 1601.992310,254.265884 
M1928.662476,205.048447 
	C1932.572510,202.443344 1936.250244,199.363815 1940.433105,197.316254 
	C1957.416504,189.002655 1975.382446,187.441650 1993.659424,191.419861 
	C2007.034058,194.331024 2017.876709,201.496017 2025.073364,213.562378 
	C2030.876587,223.292313 2033.781738,234.005432 2033.869751,245.097305 
	C2034.196411,286.253265 2034.000000,327.413391 2034.000000,368.571930 
	C2034.000000,370.325165 2034.000000,372.078400 2034.000000,373.625000 
	C2048.093750,373.625000 2061.491211,373.625000 2075.000000,373.625000 
	C2075.000000,335.095032 2075.253174,296.937317 2074.862061,258.786224 
	C2074.726074,245.511185 2074.199951,232.024597 2071.738281,219.035187 
	C2067.061523,194.358566 2053.882080,174.795502 2031.136597,163.151688 
	C2010.019653,152.341507 1987.094971,150.872040 1964.083740,154.216751 
	C1942.086548,157.414078 1922.575073,166.361313 1907.732422,183.731964 
	C1907.084595,184.490189 1906.142212,184.996826 1904.810303,186.029053 
	C1904.810303,175.314346 1904.810303,165.364075 1904.810303,155.253860 
	C1892.153076,155.253860 1879.758179,155.253860 1867.269531,155.253860 
	C1867.269531,228.227814 1867.269531,300.947601 1867.269531,373.702301 
	C1880.603271,373.702301 1893.665283,373.702301 1907.000000,373.702301 
	C1907.000000,358.767426 1906.888916,344.127502 1907.024170,329.489838 
	C1907.272949,302.569458 1907.215820,275.634460 1908.164185,248.736526 
	C1908.758911,231.864975 1916.242432,217.499573 1928.662476,205.048447 
M445.000000,209.500000 
	C445.000000,191.407455 445.000000,173.314926 445.000000,155.351685 
	C431.290283,155.351685 418.225220,155.351685 405.000000,155.351685 
	C405.000000,157.525711 405.000000,159.348679 405.000000,161.171646 
	C405.000000,203.170822 404.669922,245.174393 405.230042,287.166077 
	C405.371582,297.778137 407.434875,308.601715 410.224060,318.891968 
	C416.059082,340.419342 429.369568,356.742706 449.361115,366.535828 
	C470.317383,376.801544 492.975037,379.302429 515.877563,375.793091 
	C538.642212,372.304901 558.266113,362.291412 573.231323,342.288544 
	C573.231323,353.735168 573.231323,363.788757 573.231323,373.622406 
	C586.096008,373.622406 598.495422,373.622406 610.769775,373.622406 
	C610.769775,300.633392 610.769775,228.022156 610.769775,155.260849 
	C597.475220,155.260849 584.412048,155.260849 571.000000,155.260849 
	C571.000000,157.150024 571.000000,158.782394 571.000000,160.414764 
	C571.000000,195.580734 571.200867,230.748657 570.862610,265.911377 
	C570.775024,275.015350 569.926270,284.326385 567.801941,293.151093 
	C563.550598,310.811035 553.845398,324.637909 537.018494,333.093414 
	C520.799316,341.243500 503.664215,342.095123 486.689972,338.561493 
	C466.852264,334.431793 453.315857,321.950500 449.010956,301.533356 
	C446.865662,291.358948 445.480133,280.878052 445.172485,270.493713 
	C444.580566,250.511627 445.000000,230.499603 445.000000,209.500000 
M349.000000,145.500000 
	C349.000000,119.750381 349.000000,94.000763 349.000000,68.331650 
	C334.988464,68.331650 321.595215,68.331650 308.307892,68.331650 
	C308.307892,170.357254 308.307892,272.050934 308.307892,373.668457 
	C322.011322,373.668457 335.404572,373.668457 349.000000,373.668457 
	C349.000000,297.724731 349.000000,222.112366 349.000000,145.500000 
M31.000000,527.500000 
	C31.000000,514.162231 31.000000,500.824432 31.000000,486.992828 
	C31.966366,487.670746 32.318001,487.793365 32.465824,488.038788 
	C43.582340,506.494141 54.705833,524.945374 65.766769,543.434082 
	C68.240959,547.569763 75.172653,548.715088 77.332764,545.137451 
	C82.859695,535.983582 88.315666,526.786743 93.790627,517.601562 
	C99.968391,507.237305 106.135880,496.866852 113.042038,485.265808 
	C113.042038,510.985168 113.042038,535.319214 113.042038,559.712769 
	C118.583809,559.712769 123.645538,559.712769 128.276596,559.712769 
	C128.276596,524.417480 128.276596,489.416656 128.276596,454.001923 
	C124.109535,454.001923 120.643410,453.860260 117.201263,454.085266 
	C116.119934,454.155945 114.711723,454.958069 114.125679,455.861603 
	C111.837097,459.390198 109.824455,463.097351 107.697647,466.731384 
	C95.982651,486.748474 84.264244,506.763641 72.352501,527.111694 
	C58.588295,503.820709 45.120071,480.940552 31.494837,458.154327 
	C30.462997,456.428741 28.476528,454.535004 26.651752,454.183411 
	C23.327971,453.542999 19.792458,454.001526 16.267246,454.001526 
	C16.267246,489.558563 16.267246,524.611755 16.267246,559.704163 
	C21.268309,559.704163 25.996687,559.704163 31.000000,559.704163 
	C31.000000,549.107239 31.000000,538.803589 31.000000,527.500000 
M347.050690,465.356110 
	C338.896759,453.440857 336.311035,452.176422 325.360291,454.636597 
	C325.360291,489.733551 325.360291,524.793945 325.360291,559.786377 
	C330.670013,559.786377 335.616974,559.786377 341.036591,559.786377 
	C341.036591,534.560181 341.036591,509.542114 341.036591,483.285339 
	C348.013214,491.798981 354.237427,499.299042 360.357971,506.882782 
	C374.105743,523.917114 387.790619,541.002319 401.552795,558.024963 
	C402.247223,558.883911 403.398651,559.851196 404.387726,559.909302 
	C408.150421,560.130249 411.933868,559.998413 415.696106,559.998413 
	C415.696106,524.381165 415.696106,489.321045 415.696106,454.334167 
	C410.322571,454.334167 405.257843,454.334167 399.775208,454.334167 
	C399.775208,479.373352 399.775208,504.080597 399.775208,529.511047 
	C398.522125,528.644348 397.753113,528.332642 397.301331,527.767334 
	C388.843384,517.184631 380.477753,506.527832 371.979248,495.977997 
	C363.874298,485.916595 355.639191,475.960083 347.050690,465.356110 
M1954.958252,492.917297 
	C1952.124878,484.185120 1948.395142,475.793793 1941.726807,469.395660 
	C1932.891113,460.917847 1922.524170,455.535034 1909.979004,453.777527 
	C1890.054932,450.986298 1872.359497,455.331879 1858.255493,469.551086 
	C1844.746948,483.170166 1841.353760,500.459503 1845.049561,519.018616 
	C1848.689697,537.298035 1859.552246,549.841614 1876.688843,557.007019 
	C1888.918091,562.120422 1901.744873,562.476990 1914.335083,559.805542 
	C1929.562866,556.574341 1941.624512,548.256897 1949.445801,534.202209 
	C1956.600464,521.345337 1957.189087,507.807007 1954.958252,492.917297 
M1020.957458,484.723053 
	C1016.371704,474.662933 1009.780151,466.492920 999.956970,460.933716 
	C986.556152,453.349701 972.364624,451.251190 957.405640,454.036621 
	C942.622314,456.789307 930.492737,463.735931 921.802917,476.476318 
	C912.378540,490.293518 910.642212,505.673309 914.364441,521.068298 
	C918.299438,537.343689 928.314331,549.583496 944.520630,556.364685 
	C954.231262,560.427917 964.077637,561.396667 974.295471,560.991211 
	C986.554810,560.504822 997.500854,556.234497 1006.897766,548.526245 
	C1025.142822,533.559814 1029.898315,506.868652 1020.957458,484.723053 
M1785.457520,553.028931 
	C1787.953125,551.207520 1790.656372,549.610596 1792.909912,547.527466 
	C1805.463501,535.923035 1810.611450,521.473083 1810.115356,504.448761 
	C1809.697266,490.101471 1804.640991,477.763519 1794.443359,468.224823 
	C1780.377441,455.067932 1763.290649,450.882507 1744.140991,453.829712 
	C1727.942993,456.322662 1715.168457,463.755493 1706.136597,477.403137 
	C1697.829468,489.955536 1696.278931,503.792053 1698.984497,518.292297 
	C1703.695801,543.542419 1722.356079,557.640991 1744.991699,560.777466 
	C1758.744873,562.683228 1772.492188,560.805176 1785.457520,553.028931 
M171.911911,519.549805 
	C174.025253,532.326599 180.989487,542.215637 190.957825,549.915588 
	C202.405212,558.757935 215.699371,561.471130 230.060593,561.128479 
	C244.864822,560.775330 257.624054,555.597839 267.814545,545.430237 
	C282.285767,530.991577 286.263519,512.694641 281.489471,493.367645 
	C275.489044,469.075684 257.586914,456.040405 233.945831,453.152008 
	C219.842133,451.428833 206.204575,454.271912 194.029465,462.044220 
	C185.295609,467.619781 179.115372,475.398010 174.894562,484.946716 
	C170.029175,495.953613 169.813202,507.277130 171.911911,519.549805 
M1067.000000,471.501221 
	C1067.000000,500.911926 1067.000000,530.322632 1067.000000,559.672974 
	C1072.666504,559.672974 1077.728638,559.672974 1083.240967,559.672974 
	C1083.240967,548.951904 1083.240967,538.552368 1083.240967,528.000427 
	C1093.182129,528.000427 1102.667969,527.873169 1112.144043,528.122803 
	C1113.766602,528.165588 1115.873291,529.432922 1116.861450,530.787415 
	C1123.366699,539.704102 1129.587891,548.827454 1136.020874,557.797913 
	C1136.743530,558.805481 1138.186523,559.847534 1139.339722,559.900024 
	C1144.376099,560.128906 1149.429077,559.998474 1155.361084,559.998474 
	C1146.785522,547.738281 1138.755859,536.258484 1131.180908,525.428772 
	C1135.833862,521.865906 1140.303955,518.995667 1144.108521,515.417664 
	C1152.653076,507.381958 1154.109619,496.715607 1152.837769,485.928650 
	C1151.207153,472.099762 1143.613403,462.437775 1130.155884,457.523041 
	C1118.526611,453.276001 1106.528442,454.046173 1094.554932,454.006042 
	C1085.459595,453.975555 1076.363892,454.000000 1067.000000,454.000000 
	C1067.000000,459.893005 1067.000000,465.197418 1067.000000,471.501221 
M1604.500000,499.000000 
	C1596.737671,499.000000 1588.975464,499.000000 1580.764648,499.000000 
	C1580.764648,483.626678 1580.764648,468.891113 1580.764648,454.381195 
	C1575.223633,454.381195 1570.153564,454.381195 1565.231934,454.381195 
	C1565.231934,489.706635 1565.231934,524.650452 1565.231934,559.737793 
	C1570.530029,559.737793 1575.593140,559.737793 1581.118164,559.737793 
	C1581.118164,544.080627 1581.118164,528.678833 1581.118164,513.321167 
	C1600.981689,513.321167 1620.377930,513.321167 1640.235352,513.321167 
	C1640.235352,529.039978 1640.235352,544.441528 1640.235352,559.618896 
	C1645.776123,559.618896 1650.846191,559.618896 1655.768188,559.618896 
	C1655.768188,524.293640 1655.768188,489.349792 1655.768188,454.262177 
	C1650.470459,454.262177 1645.407227,454.262177 1639.881836,454.262177 
	C1639.881836,469.252686 1639.881836,483.988220 1639.881836,499.000000 
	C1628.112183,499.000000 1616.806030,499.000000 1604.500000,499.000000 
M600.535950,513.000000 
	C614.626770,513.000000 628.717590,513.000000 642.635864,513.000000 
	C642.635864,507.927673 642.635864,503.531067 642.635864,498.856140 
	C625.326721,498.856140 608.380127,498.856140 591.247131,498.856140 
	C591.247131,488.502655 591.247131,478.439789 591.247131,467.912720 
	C610.889709,467.912720 630.291016,467.912720 649.692993,467.912720 
	C649.692993,463.043304 649.692993,458.646210 649.692993,454.336426 
	C624.654846,454.336426 599.925171,454.336426 575.365234,454.336426 
	C575.365234,489.736572 575.365234,524.799316 575.365234,559.782471 
	C601.009460,559.782471 626.287964,559.782471 651.752441,559.782471 
	C651.752441,555.166870 651.752441,550.770447 651.752441,545.910278 
	C631.443115,545.910278 611.375671,545.910278 591.307983,545.910278 
	C591.307983,534.711487 591.307983,523.981140 591.307983,513.000000 
	C594.258362,513.000000 596.902954,513.000000 600.535950,513.000000 
M1390.616943,546.885986 
	C1396.559326,536.486511 1397.430176,525.828979 1390.746826,515.624939 
	C1385.837891,508.129822 1377.431519,505.526337 1369.297119,503.026123 
	C1359.441895,499.996948 1349.321289,497.695953 1339.712891,494.036499 
	C1330.232788,490.425873 1329.656738,478.721802 1336.155396,473.106812 
	C1344.956787,465.502380 1355.047363,466.199280 1364.999634,467.727753 
	C1372.034302,468.808075 1378.735718,472.058319 1386.246216,474.559845 
	C1387.723633,470.936279 1389.446777,466.709747 1391.278809,462.216461 
	C1373.081055,452.035004 1354.449219,449.791534 1335.282593,456.462463 
	C1321.555420,461.240112 1315.007812,471.573944 1316.029785,486.725433 
	C1316.724854,497.029419 1323.701172,505.721008 1337.336792,509.948212 
	C1347.662842,513.149414 1358.131104,515.890747 1368.474121,519.039795 
	C1375.165771,521.077148 1378.874146,525.307495 1379.000000,530.377808 
	C1379.181763,537.695251 1376.277222,542.555664 1369.843750,544.943542 
	C1361.428101,548.067139 1352.710571,548.162659 1344.015503,546.489624 
	C1334.912109,544.738037 1326.484619,541.270508 1319.251465,534.987671 
	C1317.163818,539.416565 1315.250000,543.476196 1313.173096,547.882202 
	C1314.464844,548.875305 1315.827271,550.030151 1317.293701,551.033020 
	C1329.409912,559.318604 1343.009399,561.320740 1357.363770,561.101318 
	C1370.265137,560.904114 1381.498779,557.526123 1390.616943,546.885986 
M743.401062,544.126465 
	C724.289490,551.549561 707.320007,546.426147 691.360352,535.394714 
	C690.011230,538.008545 688.637024,540.772217 687.170410,543.485962 
	C685.429504,546.707153 685.574341,548.932861 689.205566,551.122253 
	C708.020508,562.466370 728.110901,564.535217 748.462463,557.549072 
	C766.755859,551.269348 771.681396,533.426514 764.045166,517.702515 
	C759.634216,508.619781 750.239563,505.831879 741.354309,503.042969 
	C731.513733,499.954193 721.270325,497.914642 711.777954,494.016541 
	C702.360962,490.149475 701.149353,480.282898 708.260376,473.265625 
	C716.559814,465.075775 727.033264,466.202240 736.987915,467.721008 
	C744.025085,468.794617 750.728394,472.056274 758.076782,474.506592 
	C759.509399,471.480804 761.392273,467.911896 762.802063,464.165009 
	C763.068054,463.458191 761.700256,461.562378 760.665894,461.025848 
	C741.820129,451.250549 722.569031,449.484741 702.915527,458.572144 
	C692.407776,463.430695 686.865234,474.418427 688.095398,486.533325 
	C689.150085,496.918976 694.904358,504.858185 706.556458,508.888336 
	C715.848633,512.102295 725.555298,514.097534 734.942017,517.063904 
	C744.622070,520.122925 752.035034,522.494812 751.104065,533.790405 
	C750.690796,538.805298 747.300049,541.156067 743.401062,544.126465 
M818.074036,457.420044 
	C810.978638,460.554321 805.779663,465.455139 802.979187,472.872559 
	C799.572876,481.894562 800.646057,494.474823 807.998840,501.544556 
	C816.014709,509.251740 826.469727,511.101593 836.536865,514.046570 
	C843.635010,516.122986 850.890198,518.017456 857.563477,521.100830 
	C865.917664,524.960876 866.931396,534.237976 860.668091,540.887329 
	C854.516113,547.418701 846.112671,547.937988 838.463501,547.672180 
	C826.071289,547.241577 814.185730,543.651001 804.452209,534.971619 
	C802.861084,538.314941 801.531372,541.464417 799.893799,544.444702 
	C798.418579,547.129456 798.793823,548.509888 801.563660,550.349243 
	C820.217041,562.736145 840.363403,564.021301 861.061157,557.802673 
	C875.669189,553.413696 883.433228,538.548157 879.827576,523.812378 
	C877.328674,513.599426 869.642395,508.097504 860.274902,505.081970 
	C849.679199,501.670990 838.727722,499.350861 828.175781,495.823853 
	C822.623901,493.968079 817.405884,490.577759 817.049316,483.624908 
	C816.704895,476.909546 820.487244,472.879578 826.215698,469.890686 
	C833.846313,465.909332 841.969788,466.186493 849.792603,467.585327 
	C856.947266,468.864716 863.760925,472.050690 871.279846,474.583405 
	C872.426514,471.875153 873.508057,468.649292 875.120667,465.715271 
	C876.513794,463.180511 875.991089,462.081543 873.506592,460.763306 
	C855.782471,451.359314 837.541077,450.461761 818.074036,457.420044 
M1428.907837,518.538452 
	C1429.977417,527.339050 1433.822144,534.974426 1439.292480,541.728943 
	C1457.598999,564.333557 1494.549927,566.926331 1515.843018,551.972351 
	C1518.993896,549.759521 1521.711060,546.928772 1523.855469,545.058411 
	C1519.948364,540.844116 1516.822144,537.471985 1513.706299,534.111145 
	C1495.569336,553.293518 1468.715576,549.394470 1454.729614,535.000122 
	C1439.967651,519.807068 1440.573486,497.112671 1451.638916,483.021393 
	C1465.972778,464.767975 1493.859131,460.165802 1513.726562,479.649200 
	C1517.133667,476.356659 1520.535400,473.069214 1524.664062,469.079193 
	C1519.940186,465.640594 1515.664673,461.720398 1510.707520,459.080048 
	C1498.590210,452.626190 1485.538574,451.655121 1472.136963,453.991547 
	C1456.613281,456.697937 1444.339722,464.259888 1435.717163,477.664734 
	C1427.720459,490.096832 1427.068604,503.765717 1428.907837,518.538452 
M536.294556,454.001129 
	C508.229095,454.001129 480.163666,454.001129 452.233765,454.001129 
	C452.233765,459.046783 452.233765,463.328217 452.233765,468.058533 
	C464.192108,468.058533 475.926392,468.058533 488.101685,468.058533 
	C488.101685,498.908020 488.101685,529.299316 488.101685,559.693542 
	C493.289490,559.693542 498.018158,559.693542 503.194397,559.693542 
	C503.194397,528.998779 503.194397,498.607971 503.194397,467.762695 
	C515.378296,467.762695 527.113159,467.762695 538.995239,467.762695 
	C538.995239,463.647797 539.096802,459.987701 538.916016,456.341644 
	C538.876221,455.539154 537.834412,454.786407 536.294556,454.001129 
M2044.500000,546.000000 
	C2034.405396,546.000000 2024.310791,546.000000 2013.765381,546.000000 
	C2013.765381,514.961243 2013.765381,484.562622 2013.765381,454.380585 
	C2008.224976,454.380585 2003.154907,454.380585 1998.232178,454.380585 
	C1998.232178,489.705658 1998.232178,524.649719 1998.232178,559.739197 
	C2022.527832,559.739197 2046.590576,559.739197 2070.708496,559.739197 
	C2070.708496,555.076111 2070.708496,550.679321 2070.708496,546.000000 
	C2062.113525,546.000000 2053.806641,546.000000 2044.500000,546.000000 
M1198.692505,454.001251 
	C1194.794189,453.619873 1196.044067,456.569183 1196.041016,458.385742 
	C1195.984863,491.176270 1196.000122,523.966919 1196.000366,556.757568 
	C1196.000366,557.705994 1196.000488,558.654358 1196.000488,559.741821 
	C1201.530762,559.741821 1206.589233,559.741821 1211.759521,559.741821 
	C1211.759521,524.452026 1211.759521,489.420807 1211.759521,454.000092 
	C1207.572876,454.000092 1203.615601,454.000092 1198.692505,454.001251 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1313.000000,99.006577 
	C1313.000000,190.795975 1313.000000,282.087036 1313.000000,373.688934 
	C1299.359741,373.688934 1285.964478,373.688934 1272.128662,373.688934 
	C1272.128662,305.333374 1272.128662,236.969635 1272.128662,168.605911 
	C1271.776123,168.464584 1271.423584,168.323257 1271.070923,168.181931 
	C1268.420044,172.306885 1265.650024,176.360626 1263.137451,180.568176 
	C1244.449219,211.863190 1225.781982,243.170746 1207.169556,274.510834 
	C1195.091553,294.848358 1183.161377,315.273773 1171.007324,335.565460 
	C1170.090088,337.096924 1167.967651,338.734314 1166.307373,338.844269 
	C1160.343018,339.239197 1154.336670,338.999237 1148.696655,338.999237 
	C1114.626099,282.217834 1080.749756,225.760315 1046.160034,168.113708 
	C1046.160034,237.458511 1046.160034,305.348999 1046.160034,373.619568 
	C1032.502686,373.619568 1019.105286,373.619568 1005.352783,373.619568 
	C1005.352783,277.786438 1005.352783,181.738220 1005.352783,85.013313 
	C1016.254761,85.013313 1027.341919,84.957054 1038.425903,85.116859 
	C1039.342651,85.130066 1040.507324,86.444145 1041.103394,87.428429 
	C1049.244385,100.871773 1057.356323,114.333313 1065.373657,127.850754 
	C1076.448975,146.523819 1087.387939,165.277878 1098.478882,183.941528 
	C1108.577759,200.935669 1118.855591,217.823395 1128.955200,234.817017 
	C1139.215820,252.081497 1149.354980,269.418152 1159.647339,286.893097 
	C1165.075439,277.727142 1170.542358,268.569885 1175.937012,259.370209 
	C1186.669922,241.067337 1197.352173,222.734818 1208.075684,204.426498 
	C1216.675903,189.743073 1225.326782,175.089188 1233.918091,160.400482 
	C1243.649048,143.763306 1253.341675,127.103752 1263.044556,110.450104 
	C1267.203613,103.311707 1271.464233,96.228508 1275.435059,88.986626 
	C1276.941895,86.238426 1278.572388,84.847603 1281.925293,84.920403 
	C1292.066284,85.140640 1302.214966,85.000008 1313.000000,85.000008 
	C1313.000000,89.647171 1313.000000,94.077705 1313.000000,99.006577 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M63.000000,85.000000 
	C93.497986,85.000000 123.496605,84.880798 153.493744,85.040825 
	C174.389191,85.152290 194.890060,88.311707 213.766510,97.521141 
	C233.421753,107.110558 246.679184,122.254936 251.136490,144.526184 
	C254.566147,161.662750 252.713821,178.063522 245.536591,193.632782 
	C240.213547,205.179840 231.871078,214.636810 220.579041,221.091156 
	C219.451721,221.735504 218.369171,222.466522 217.313980,223.224365 
	C216.931122,223.499313 216.689209,223.970474 216.124908,224.673767 
	C228.469299,227.809464 239.086258,234.066559 247.857010,242.671494 
	C256.884552,251.528351 262.871490,262.612854 265.586548,275.158997 
	C270.353943,297.188843 268.679169,318.234100 256.077881,337.686707 
	C247.704468,350.612701 235.325302,358.225647 221.603424,363.924133 
	C208.620758,369.315735 195.017441,372.363098 180.919357,373.021332 
	C175.822937,373.259308 170.734955,373.948334 165.642365,373.956390 
	C118.812294,374.030396 71.982056,374.000000 25.151867,374.000000 
	C23.330133,374.000000 21.508398,374.000000 19.344770,374.000000 
	C19.344770,277.759644 19.344770,181.700714 19.344770,85.000000 
	C33.647449,85.000000 48.073723,85.000000 63.000000,85.000000 
M207.587540,328.060638 
	C213.223083,325.025909 216.985504,320.351410 220.104965,314.817200 
	C225.110931,305.936188 225.701538,296.332489 224.883133,286.674530 
	C223.860641,274.608124 218.460358,264.874298 208.302826,257.577545 
	C194.754745,247.845139 179.065323,245.630722 163.404068,245.243896 
	C130.764465,244.437668 98.091064,244.996719 65.431557,245.011047 
	C64.004448,245.011673 62.577415,245.173691 61.369278,245.247192 
	C61.369278,277.085266 61.369278,308.478058 61.369278,340.369171 
	C101.559975,338.479980 141.482529,342.648224 181.226242,337.896088 
	C190.397781,336.799438 199.277924,334.032440 207.587540,328.060638 
M209.963226,157.025970 
	C209.737259,156.589096 209.386566,156.174683 209.303314,155.712128 
	C206.359451,139.355377 195.610489,130.351303 180.780411,124.897339 
	C166.505157,119.647408 151.650146,119.153168 136.755127,119.040604 
	C115.598343,118.880730 94.439461,119.000000 73.281471,119.000000 
	C69.326477,119.000000 65.371490,119.000000 61.238220,119.000000 
	C61.238220,149.829147 61.238220,180.216904 61.238220,210.534821 
	C61.764072,210.731827 62.059135,210.940979 62.352116,210.938110 
	C93.098793,210.636200 123.855255,210.665176 154.585663,209.785202 
	C163.535095,209.528915 172.668259,207.501617 181.259598,204.820007 
	C194.613083,200.651993 204.993378,192.337448 208.323349,177.997940 
	C209.825317,171.530075 209.495026,164.636703 209.963226,157.025970 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M768.974365,377.000000 
	C746.004028,376.903229 724.411316,373.046326 704.444153,362.101471 
	C687.037354,352.559967 673.114807,339.502014 663.505920,322.011993 
	C655.668884,307.747009 651.372192,292.603668 649.809387,276.288971 
	C647.360107,250.720886 652.066467,226.909378 664.761169,204.771515 
	C678.270081,181.213699 698.626587,166.055557 724.416626,158.014771 
	C738.850952,153.514465 753.739563,151.992371 768.690308,153.134705 
	C789.932495,154.757736 809.700623,161.282150 826.782776,174.409378 
	C844.146912,187.753342 855.718384,205.348099 861.801819,226.228760 
	C864.507507,235.515686 865.812317,245.300568 866.842834,254.956558 
	C867.634216,262.371460 867.000427,269.938416 867.000427,277.864532 
	C808.029846,277.864532 749.272339,277.864532 689.300476,277.864532 
	C691.141296,284.350525 692.228333,290.377289 694.526917,295.899719 
	C704.586975,320.069275 723.233398,333.710785 748.502136,339.031128 
	C769.527954,343.458099 789.950745,341.638214 809.033997,331.774078 
	C817.050110,327.630554 823.894958,321.220947 831.994751,315.314697 
	C838.898926,323.296234 846.474060,332.053406 854.238464,341.029358 
	C831.598633,366.573883 802.391235,376.138855 768.974365,377.000000 
M696.048279,226.669128 
	C693.781982,233.904922 691.515747,241.140732 689.138550,248.730682 
	C736.595093,248.730682 782.570374,248.730682 828.582947,248.730682 
	C823.611877,213.989090 800.507263,189.288284 761.201050,187.294113 
	C738.582031,186.146530 710.335083,196.087631 696.048279,226.669128 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1578.738647,301.614288 
	C1572.642822,320.599670 1562.585693,336.703339 1547.870605,349.470093 
	C1531.857910,363.362610 1513.141235,372.097015 1492.011597,374.917084 
	C1483.072876,376.110107 1473.957031,377.279144 1465.004761,376.823700 
	C1437.698486,375.434540 1412.962402,367.249817 1391.953003,348.663513 
	C1373.730835,332.542969 1362.829834,312.321838 1358.326904,289.123413 
	C1352.210571,257.611725 1356.649170,227.331482 1375.024536,200.165573 
	C1391.141846,176.337738 1414.026611,162.447235 1441.759155,155.991196 
	C1454.541626,153.015488 1467.563843,152.076965 1480.522827,153.146225 
	C1502.168579,154.932220 1522.727783,161.022644 1540.270874,174.198380 
	C1559.256226,188.457245 1572.610718,206.859329 1579.245483,230.177155 
	C1583.506470,245.152573 1584.744995,260.235718 1583.791504,275.480347 
	C1583.250000,284.140381 1580.629150,292.670380 1578.738647,301.614288 
M1397.991089,254.363876 
	C1397.660767,256.453827 1397.095947,258.537384 1397.038696,260.634796 
	C1396.701294,272.994507 1398.424194,285.014252 1402.987427,296.593384 
	C1409.603516,313.381378 1421.037476,325.958466 1437.065308,333.838287 
	C1451.475708,340.923035 1466.912964,342.654602 1482.902222,340.063171 
	C1505.858765,336.342529 1523.080811,324.465668 1533.891113,304.027161 
	C1543.827271,285.241394 1545.736572,264.972504 1540.866943,244.502502 
	C1530.654785,201.574829 1493.470459,182.669662 1453.492065,190.411118 
	C1437.505737,193.506744 1423.885010,201.520126 1413.565308,214.789627 
	C1404.621582,226.289902 1399.498901,239.080185 1397.991089,254.363876 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1601.995850,253.799652 
	C1603.106445,232.853119 1609.881348,214.318680 1621.813843,197.774261 
	C1637.096436,176.585251 1657.845581,163.451920 1683.068848,156.934647 
	C1696.758301,153.397522 1710.673584,151.981857 1724.603882,153.150192 
	C1751.851685,155.435379 1776.366455,164.314667 1796.592896,183.878281 
	C1813.951660,200.668198 1824.291748,220.988220 1827.753052,244.351868 
	C1832.481445,276.268768 1827.467285,306.762817 1807.817261,333.262146 
	C1791.658691,355.052979 1769.695801,368.729156 1743.005249,373.825562 
	C1732.114746,375.905029 1720.786743,377.268219 1709.760498,376.753510 
	C1680.998535,375.410858 1655.164062,366.352386 1633.951538,345.645599 
	C1617.479370,329.566010 1607.556152,310.231384 1603.242310,288.034576 
	C1601.405273,278.582184 1601.624878,268.722168 1601.059692,259.038635 
	C1600.968262,257.471191 1601.661743,255.857956 1601.995850,253.799652 
M1788.000000,263.521820 
	C1788.230957,248.691620 1784.630737,234.895950 1777.034302,222.118927 
	C1769.861572,210.054642 1759.716064,201.062103 1747.216797,195.223480 
	C1732.934448,188.551895 1717.679688,187.611099 1702.090698,189.927689 
	C1687.437256,192.105255 1674.677002,198.191193 1664.245728,208.426514 
	C1649.753906,222.646500 1643.109741,240.695404 1642.127808,260.645782 
	C1641.363647,276.170227 1644.326294,291.242096 1652.049194,304.938293 
	C1661.435181,321.583984 1675.306519,332.740295 1693.843994,338.088593 
	C1705.436279,341.433105 1717.076660,341.928894 1728.884521,339.988831 
	C1746.170166,337.148743 1760.847412,329.222717 1771.649048,315.513031 
	C1783.304565,300.719696 1788.680054,283.475494 1788.000000,263.521820 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1928.358521,205.247925 
	C1916.242432,217.499573 1908.758911,231.864975 1908.164185,248.736526 
	C1907.215820,275.634460 1907.272949,302.569458 1907.024170,329.489838 
	C1906.888916,344.127502 1907.000000,358.767426 1907.000000,373.702301 
	C1893.665283,373.702301 1880.603271,373.702301 1867.269531,373.702301 
	C1867.269531,300.947601 1867.269531,228.227814 1867.269531,155.253860 
	C1879.758179,155.253860 1892.153076,155.253860 1904.810303,155.253860 
	C1904.810303,165.364075 1904.810303,175.314346 1904.810303,186.029053 
	C1906.142212,184.996826 1907.084595,184.490189 1907.732422,183.731964 
	C1922.575073,166.361313 1942.086548,157.414078 1964.083740,154.216751 
	C1987.094971,150.872040 2010.019653,152.341507 2031.136597,163.151688 
	C2053.882080,174.795502 2067.061523,194.358566 2071.738281,219.035187 
	C2074.199951,232.024597 2074.726074,245.511185 2074.862061,258.786224 
	C2075.253174,296.937317 2075.000000,335.095032 2075.000000,373.625000 
	C2061.491211,373.625000 2048.093750,373.625000 2034.000000,373.625000 
	C2034.000000,372.078400 2034.000000,370.325165 2034.000000,368.571930 
	C2034.000000,327.413391 2034.196411,286.253265 2033.869751,245.097305 
	C2033.781738,234.005432 2030.876587,223.292313 2025.073364,213.562378 
	C2017.876709,201.496017 2007.034058,194.331024 1993.659424,191.419861 
	C1975.382446,187.441650 1957.416504,189.002655 1940.433105,197.316254 
	C1936.250244,199.363815 1932.572510,202.443344 1928.358521,205.247925 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M445.000000,210.000000 
	C445.000000,230.499603 444.580566,250.511627 445.172485,270.493713 
	C445.480133,280.878052 446.865662,291.358948 449.010956,301.533356 
	C453.315857,321.950500 466.852264,334.431793 486.689972,338.561493 
	C503.664215,342.095123 520.799316,341.243500 537.018494,333.093414 
	C553.845398,324.637909 563.550598,310.811035 567.801941,293.151093 
	C569.926270,284.326385 570.775024,275.015350 570.862610,265.911377 
	C571.200867,230.748657 571.000000,195.580734 571.000000,160.414764 
	C571.000000,158.782394 571.000000,157.150024 571.000000,155.260849 
	C584.412048,155.260849 597.475220,155.260849 610.769775,155.260849 
	C610.769775,228.022156 610.769775,300.633392 610.769775,373.622406 
	C598.495422,373.622406 586.096008,373.622406 573.231323,373.622406 
	C573.231323,363.788757 573.231323,353.735168 573.231323,342.288544 
	C558.266113,362.291412 538.642212,372.304901 515.877563,375.793091 
	C492.975037,379.302429 470.317383,376.801544 449.361115,366.535828 
	C429.369568,356.742706 416.059082,340.419342 410.224060,318.891968 
	C407.434875,308.601715 405.371582,297.778137 405.230042,287.166077 
	C404.669922,245.174393 405.000000,203.170822 405.000000,161.171646 
	C405.000000,159.348679 405.000000,157.525711 405.000000,155.351685 
	C418.225220,155.351685 431.290283,155.351685 445.000000,155.351685 
	C445.000000,173.314926 445.000000,191.407455 445.000000,210.000000 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M349.000000,146.000000 
	C349.000000,222.112366 349.000000,297.724731 349.000000,373.668457 
	C335.404572,373.668457 322.011322,373.668457 308.307892,373.668457 
	C308.307892,272.050934 308.307892,170.357254 308.307892,68.331650 
	C321.595215,68.331650 334.988464,68.331650 349.000000,68.331650 
	C349.000000,94.000763 349.000000,119.750381 349.000000,146.000000 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M31.000000,528.000000 
	C31.000000,538.803589 31.000000,549.107239 31.000000,559.704163 
	C25.996687,559.704163 21.268309,559.704163 16.267246,559.704163 
	C16.267246,524.611755 16.267246,489.558563 16.267246,454.001526 
	C19.792458,454.001526 23.327971,453.542999 26.651752,454.183411 
	C28.476528,454.535004 30.462997,456.428741 31.494837,458.154327 
	C45.120071,480.940552 58.588295,503.820709 72.352501,527.111694 
	C84.264244,506.763641 95.982651,486.748474 107.697647,466.731384 
	C109.824455,463.097351 111.837097,459.390198 114.125679,455.861603 
	C114.711723,454.958069 116.119934,454.155945 117.201263,454.085266 
	C120.643410,453.860260 124.109535,454.001923 128.276596,454.001923 
	C128.276596,489.416656 128.276596,524.417480 128.276596,559.712769 
	C123.645538,559.712769 118.583809,559.712769 113.042038,559.712769 
	C113.042038,535.319214 113.042038,510.985168 113.042038,485.265808 
	C106.135880,496.866852 99.968391,507.237305 93.790627,517.601562 
	C88.315666,526.786743 82.859695,535.983582 77.332764,545.137451 
	C75.172653,548.715088 68.240959,547.569763 65.766769,543.434082 
	C54.705833,524.945374 43.582340,506.494141 32.465824,488.038788 
	C32.318001,487.793365 31.966366,487.670746 31.000000,486.992828 
	C31.000000,500.824432 31.000000,514.162231 31.000000,528.000000 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M347.255981,465.656799 
	C355.639191,475.960083 363.874298,485.916595 371.979248,495.977997 
	C380.477753,506.527832 388.843384,517.184631 397.301331,527.767334 
	C397.753113,528.332642 398.522125,528.644348 399.775208,529.511047 
	C399.775208,504.080597 399.775208,479.373352 399.775208,454.334167 
	C405.257843,454.334167 410.322571,454.334167 415.696106,454.334167 
	C415.696106,489.321045 415.696106,524.381165 415.696106,559.998413 
	C411.933868,559.998413 408.150421,560.130249 404.387726,559.909302 
	C403.398651,559.851196 402.247223,558.883911 401.552795,558.024963 
	C387.790619,541.002319 374.105743,523.917114 360.357971,506.882782 
	C354.237427,499.299042 348.013214,491.798981 341.036591,483.285339 
	C341.036591,509.542114 341.036591,534.560181 341.036591,559.786377 
	C335.616974,559.786377 330.670013,559.786377 325.360291,559.786377 
	C325.360291,524.793945 325.360291,489.733551 325.360291,454.636597 
	C336.311035,452.176422 338.896759,453.440857 347.255981,465.656799 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1954.976929,493.348572 
	C1957.189087,507.807007 1956.600464,521.345337 1949.445801,534.202209 
	C1941.624512,548.256897 1929.562866,556.574341 1914.335083,559.805542 
	C1901.744873,562.476990 1888.918091,562.120422 1876.688843,557.007019 
	C1859.552246,549.841614 1848.689697,537.298035 1845.049561,519.018616 
	C1841.353760,500.459503 1844.746948,483.170166 1858.255493,469.551086 
	C1872.359497,455.331879 1890.054932,450.986298 1909.979004,453.777527 
	C1922.524170,455.535034 1932.891113,460.917847 1941.726807,469.395660 
	C1948.395142,475.793793 1952.124878,484.185120 1954.976929,493.348572 
M1938.997559,517.239014 
	C1939.331665,513.499878 1939.907715,509.764130 1939.961304,506.020966 
	C1940.330322,480.245697 1914.641479,462.244080 1890.326904,468.345764 
	C1871.000610,473.195648 1859.429810,490.113251 1859.826782,508.007843 
	C1860.437012,535.508545 1886.703369,552.365601 1911.163330,545.426147 
	C1925.494141,541.360413 1934.348511,531.932373 1938.997559,517.239014 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1021.016663,485.112671 
	C1029.898315,506.868652 1025.142822,533.559814 1006.897766,548.526245 
	C997.500854,556.234497 986.554810,560.504822 974.295471,560.991211 
	C964.077637,561.396667 954.231262,560.427917 944.520630,556.364685 
	C928.314331,549.583496 918.299438,537.343689 914.364441,521.068298 
	C910.642212,505.673309 912.378540,490.293518 921.802917,476.476318 
	C930.492737,463.735931 942.622314,456.789307 957.405640,454.036621 
	C972.364624,451.251190 986.556152,453.349701 999.956970,460.933716 
	C1009.780151,466.492920 1016.371704,474.662933 1021.016663,485.112671 
M1006.959412,492.861450 
	C1003.966797,486.744720 1000.828979,480.870758 995.156860,476.542023 
	C983.982910,468.014526 971.889404,465.052460 958.225525,468.922943 
	C941.882812,473.552246 931.973633,484.338501 929.240356,500.874237 
	C927.009399,514.370605 930.882996,526.918884 940.998596,536.168823 
	C950.866943,545.192566 962.868103,548.530518 976.663208,546.149048 
	C1002.444336,541.698425 1014.406250,516.659241 1006.959412,492.861450 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1785.193726,553.297852 
	C1772.492188,560.805176 1758.744873,562.683228 1744.991699,560.777466 
	C1722.356079,557.640991 1703.695801,543.542419 1698.984497,518.292297 
	C1696.278931,503.792053 1697.829468,489.955536 1706.136597,477.403137 
	C1715.168457,463.755493 1727.942993,456.322662 1744.140991,453.829712 
	C1763.290649,450.882507 1780.377441,455.067932 1794.443359,468.224823 
	C1804.640991,477.763519 1809.697266,490.101471 1810.115356,504.448761 
	C1810.611450,521.473083 1805.463501,535.923035 1792.909912,547.527466 
	C1790.656372,549.610596 1787.953125,551.207520 1785.193726,553.297852 
M1743.122314,545.624451 
	C1749.261230,545.753052 1755.523804,546.731812 1761.517334,545.860413 
	C1785.156616,542.423462 1796.628174,521.757385 1793.787598,500.151886 
	C1792.752808,492.280670 1789.074097,485.165009 1783.000610,479.322327 
	C1776.144165,472.726501 1768.442627,469.114899 1758.967041,467.757812 
	C1747.713867,466.146057 1737.780884,469.085541 1729.283569,475.514709 
	C1718.550293,483.635620 1713.444214,494.909637 1713.867310,508.863068 
	C1714.348633,524.742493 1725.657593,541.402100 1743.122314,545.624451 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M171.637512,519.207581 
	C169.813202,507.277130 170.029175,495.953613 174.894562,484.946716 
	C179.115372,475.398010 185.295609,467.619781 194.029465,462.044220 
	C206.204575,454.271912 219.842133,451.428833 233.945831,453.152008 
	C257.586914,456.040405 275.489044,469.075684 281.489471,493.367645 
	C286.263519,512.694641 282.285767,530.991577 267.814545,545.430237 
	C257.624054,555.597839 244.864822,560.775330 230.060593,561.128479 
	C215.699371,561.471130 202.405212,558.757935 190.957825,549.915588 
	C180.989487,542.215637 174.025253,532.326599 171.637512,519.207581 
M198.006866,535.509216 
	C205.174301,542.505981 214.022507,546.099487 223.757385,546.904480 
	C242.580765,548.461243 260.391998,536.824768 265.326294,518.299927 
	C269.248993,503.572968 266.553833,490.430511 255.875748,479.502106 
	C246.318344,469.720703 234.637070,465.924347 220.809814,467.904388 
	C198.208939,471.140717 186.729248,488.091125 186.302368,507.246033 
	C186.074860,517.454468 189.625839,527.412781 198.006866,535.509216 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1067.000000,471.001526 
	C1067.000000,465.197418 1067.000000,459.893005 1067.000000,454.000000 
	C1076.363892,454.000000 1085.459595,453.975555 1094.554932,454.006042 
	C1106.528442,454.046173 1118.526611,453.276001 1130.155884,457.523041 
	C1143.613403,462.437775 1151.207153,472.099762 1152.837769,485.928650 
	C1154.109619,496.715607 1152.653076,507.381958 1144.108521,515.417664 
	C1140.303955,518.995667 1135.833862,521.865906 1131.180908,525.428772 
	C1138.755859,536.258484 1146.785522,547.738281 1155.361084,559.998474 
	C1149.429077,559.998474 1144.376099,560.128906 1139.339722,559.900024 
	C1138.186523,559.847534 1136.743530,558.805481 1136.020874,557.797913 
	C1129.587891,548.827454 1123.366699,539.704102 1116.861450,530.787415 
	C1115.873291,529.432922 1113.766602,528.165588 1112.144043,528.122803 
	C1102.667969,527.873169 1093.182129,528.000427 1083.240967,528.000427 
	C1083.240967,538.552368 1083.240967,548.951904 1083.240967,559.672974 
	C1077.728638,559.672974 1072.666504,559.672974 1067.000000,559.672974 
	C1067.000000,530.322632 1067.000000,500.911926 1067.000000,471.001526 
M1083.000000,473.609863 
	C1083.000000,487.015076 1083.000000,500.420258 1083.000000,514.000000 
	C1092.994141,514.000000 1102.306396,514.134949 1111.610718,513.925720 
	C1114.806519,513.853882 1118.034424,513.127563 1121.158691,512.344727 
	C1131.298950,509.803955 1137.733643,500.641357 1136.971680,489.984131 
	C1136.273438,480.217682 1131.771118,471.950348 1120.849243,470.184967 
	C1108.595215,468.204224 1096.043457,468.065002 1083.268677,467.097870 
	C1083.140503,469.740997 1083.070190,471.188690 1083.000000,473.609863 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1605.000000,499.000000 
	C1616.806030,499.000000 1628.112183,499.000000 1639.881836,499.000000 
	C1639.881836,483.988220 1639.881836,469.252686 1639.881836,454.262177 
	C1645.407227,454.262177 1650.470459,454.262177 1655.768188,454.262177 
	C1655.768188,489.349792 1655.768188,524.293640 1655.768188,559.618896 
	C1650.846191,559.618896 1645.776123,559.618896 1640.235352,559.618896 
	C1640.235352,544.441528 1640.235352,529.039978 1640.235352,513.321167 
	C1620.377930,513.321167 1600.981689,513.321167 1581.118164,513.321167 
	C1581.118164,528.678833 1581.118164,544.080627 1581.118164,559.737793 
	C1575.593140,559.737793 1570.530029,559.737793 1565.231934,559.737793 
	C1565.231934,524.650452 1565.231934,489.706635 1565.231934,454.381195 
	C1570.153564,454.381195 1575.223633,454.381195 1580.764648,454.381195 
	C1580.764648,468.891113 1580.764648,483.626678 1580.764648,499.000000 
	C1588.975464,499.000000 1596.737671,499.000000 1605.000000,499.000000 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M600.041748,513.000000 
	C596.902954,513.000000 594.258362,513.000000 591.307983,513.000000 
	C591.307983,523.981140 591.307983,534.711487 591.307983,545.910278 
	C611.375671,545.910278 631.443115,545.910278 651.752441,545.910278 
	C651.752441,550.770447 651.752441,555.166870 651.752441,559.782471 
	C626.287964,559.782471 601.009460,559.782471 575.365234,559.782471 
	C575.365234,524.799316 575.365234,489.736572 575.365234,454.336426 
	C599.925171,454.336426 624.654846,454.336426 649.692993,454.336426 
	C649.692993,458.646210 649.692993,463.043304 649.692993,467.912720 
	C630.291016,467.912720 610.889709,467.912720 591.247131,467.912720 
	C591.247131,478.439789 591.247131,488.502655 591.247131,498.856140 
	C608.380127,498.856140 625.326721,498.856140 642.635864,498.856140 
	C642.635864,503.531067 642.635864,507.927673 642.635864,513.000000 
	C628.717590,513.000000 614.626770,513.000000 600.041748,513.000000 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1390.345703,547.139343 
	C1381.498779,557.526123 1370.265137,560.904114 1357.363770,561.101318 
	C1343.009399,561.320740 1329.409912,559.318604 1317.293701,551.033020 
	C1315.827271,550.030151 1314.464844,548.875305 1313.173096,547.882202 
	C1315.250000,543.476196 1317.163818,539.416565 1319.251465,534.987671 
	C1326.484619,541.270508 1334.912109,544.738037 1344.015503,546.489624 
	C1352.710571,548.162659 1361.428101,548.067139 1369.843750,544.943542 
	C1376.277222,542.555664 1379.181763,537.695251 1379.000000,530.377808 
	C1378.874146,525.307495 1375.165771,521.077148 1368.474121,519.039795 
	C1358.131104,515.890747 1347.662842,513.149414 1337.336792,509.948212 
	C1323.701172,505.721008 1316.724854,497.029419 1316.029785,486.725433 
	C1315.007812,471.573944 1321.555420,461.240112 1335.282593,456.462463 
	C1354.449219,449.791534 1373.081055,452.035004 1391.278809,462.216461 
	C1389.446777,466.709747 1387.723633,470.936279 1386.246216,474.559845 
	C1378.735718,472.058319 1372.034302,468.808075 1364.999634,467.727753 
	C1355.047363,466.199280 1344.956787,465.502380 1336.155396,473.106812 
	C1329.656738,478.721802 1330.232788,490.425873 1339.712891,494.036499 
	C1349.321289,497.695953 1359.441895,499.996948 1369.297119,503.026123 
	C1377.431519,505.526337 1385.837891,508.129822 1390.746826,515.624939 
	C1397.430176,525.828979 1396.559326,536.486511 1390.345703,547.139343 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M743.779297,544.051025 
	C747.300049,541.156067 750.690796,538.805298 751.104065,533.790405 
	C752.035034,522.494812 744.622070,520.122925 734.942017,517.063904 
	C725.555298,514.097534 715.848633,512.102295 706.556458,508.888336 
	C694.904358,504.858185 689.150085,496.918976 688.095398,486.533325 
	C686.865234,474.418427 692.407776,463.430695 702.915527,458.572144 
	C722.569031,449.484741 741.820129,451.250549 760.665894,461.025848 
	C761.700256,461.562378 763.068054,463.458191 762.802063,464.165009 
	C761.392273,467.911896 759.509399,471.480804 758.076782,474.506592 
	C750.728394,472.056274 744.025085,468.794617 736.987915,467.721008 
	C727.033264,466.202240 716.559814,465.075775 708.260376,473.265625 
	C701.149353,480.282898 702.360962,490.149475 711.777954,494.016541 
	C721.270325,497.914642 731.513733,499.954193 741.354309,503.042969 
	C750.239563,505.831879 759.634216,508.619781 764.045166,517.702515 
	C771.681396,533.426514 766.755859,551.269348 748.462463,557.549072 
	C728.110901,564.535217 708.020508,562.466370 689.205566,551.122253 
	C685.574341,548.932861 685.429504,546.707153 687.170410,543.485962 
	C688.637024,540.772217 690.011230,538.008545 691.360352,535.394714 
	C707.320007,546.426147 724.289490,551.549561 743.779297,544.051025 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M818.417358,457.228455 
	C837.541077,450.461761 855.782471,451.359314 873.506592,460.763306 
	C875.991089,462.081543 876.513794,463.180511 875.120667,465.715271 
	C873.508057,468.649292 872.426514,471.875153 871.279846,474.583405 
	C863.760925,472.050690 856.947266,468.864716 849.792603,467.585327 
	C841.969788,466.186493 833.846313,465.909332 826.215698,469.890686 
	C820.487244,472.879578 816.704895,476.909546 817.049316,483.624908 
	C817.405884,490.577759 822.623901,493.968079 828.175781,495.823853 
	C838.727722,499.350861 849.679199,501.670990 860.274902,505.081970 
	C869.642395,508.097504 877.328674,513.599426 879.827576,523.812378 
	C883.433228,538.548157 875.669189,553.413696 861.061157,557.802673 
	C840.363403,564.021301 820.217041,562.736145 801.563660,550.349243 
	C798.793823,548.509888 798.418579,547.129456 799.893799,544.444702 
	C801.531372,541.464417 802.861084,538.314941 804.452209,534.971619 
	C814.185730,543.651001 826.071289,547.241577 838.463501,547.672180 
	C846.112671,547.937988 854.516113,547.418701 860.668091,540.887329 
	C866.931396,534.237976 865.917664,524.960876 857.563477,521.100830 
	C850.890198,518.017456 843.635010,516.122986 836.536865,514.046570 
	C826.469727,511.101593 816.014709,509.251740 807.998840,501.544556 
	C800.646057,494.474823 799.572876,481.894562 802.979187,472.872559 
	C805.779663,465.455139 810.978638,460.554321 818.417358,457.228455 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1428.625977,518.190063 
	C1427.068604,503.765717 1427.720459,490.096832 1435.717163,477.664734 
	C1444.339722,464.259888 1456.613281,456.697937 1472.136963,453.991547 
	C1485.538574,451.655121 1498.590210,452.626190 1510.707520,459.080048 
	C1515.664673,461.720398 1519.940186,465.640594 1524.664062,469.079193 
	C1520.535400,473.069214 1517.133667,476.356659 1513.726562,479.649200 
	C1493.859131,460.165802 1465.972778,464.767975 1451.638916,483.021393 
	C1440.573486,497.112671 1439.967651,519.807068 1454.729614,535.000122 
	C1468.715576,549.394470 1495.569336,553.293518 1513.706299,534.111145 
	C1516.822144,537.471985 1519.948364,540.844116 1523.855469,545.058411 
	C1521.711060,546.928772 1518.993896,549.759521 1515.843018,551.972351 
	C1494.549927,566.926331 1457.598999,564.333557 1439.292480,541.728943 
	C1433.822144,534.974426 1429.977417,527.339050 1428.625977,518.190063 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M536.774292,454.005920 
	C537.834412,454.786407 538.876221,455.539154 538.916016,456.341644 
	C539.096802,459.987701 538.995239,463.647797 538.995239,467.762695 
	C527.113159,467.762695 515.378296,467.762695 503.194397,467.762695 
	C503.194397,498.607971 503.194397,528.998779 503.194397,559.693542 
	C498.018158,559.693542 493.289490,559.693542 488.101685,559.693542 
	C488.101685,529.299316 488.101685,498.908020 488.101685,468.058533 
	C475.926392,468.058533 464.192108,468.058533 452.233765,468.058533 
	C452.233765,463.328217 452.233765,459.046783 452.233765,454.001129 
	C480.163666,454.001129 508.229095,454.001129 536.774292,454.005920 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M2045.000000,546.000000 
	C2053.806641,546.000000 2062.113525,546.000000 2070.708496,546.000000 
	C2070.708496,550.679321 2070.708496,555.076111 2070.708496,559.739197 
	C2046.590576,559.739197 2022.527832,559.739197 1998.232178,559.739197 
	C1998.232178,524.649719 1998.232178,489.705658 1998.232178,454.380585 
	C2003.154907,454.380585 2008.224976,454.380585 2013.765381,454.380585 
	C2013.765381,484.562622 2013.765381,514.961243 2013.765381,546.000000 
	C2024.310791,546.000000 2034.405396,546.000000 2045.000000,546.000000 
z"/>
<path fill="#004AAD" opacity="1.000000" stroke="none" 
	d="
M1199.175415,454.000671 
	C1203.615601,454.000092 1207.572876,454.000092 1211.759521,454.000092 
	C1211.759521,489.420807 1211.759521,524.452026 1211.759521,559.741821 
	C1206.589233,559.741821 1201.530762,559.741821 1196.000488,559.741821 
	C1196.000488,558.654358 1196.000366,557.705994 1196.000366,556.757568 
	C1196.000122,523.966919 1195.984863,491.176270 1196.041016,458.385742 
	C1196.044067,456.569183 1194.794189,453.619873 1199.175415,454.000671 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M207.293076,328.289978 
	C199.277924,334.032440 190.397781,336.799438 181.226242,337.896088 
	C141.482529,342.648224 101.559975,338.479980 61.369278,340.369171 
	C61.369278,308.478058 61.369278,277.085266 61.369278,245.247192 
	C62.577415,245.173691 64.004448,245.011673 65.431557,245.011047 
	C98.091064,244.996719 130.764465,244.437668 163.404068,245.243896 
	C179.065323,245.630722 194.754745,247.845139 208.302826,257.577545 
	C218.460358,264.874298 223.860641,274.608124 224.883133,286.674530 
	C225.701538,296.332489 225.110931,305.936188 220.104965,314.817200 
	C216.985504,320.351410 213.223083,325.025909 207.293076,328.289978 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M209.979645,157.481171 
	C209.495026,164.636703 209.825317,171.530075 208.323349,177.997940 
	C204.993378,192.337448 194.613083,200.651993 181.259598,204.820007 
	C172.668259,207.501617 163.535095,209.528915 154.585663,209.785202 
	C123.855255,210.665176 93.098793,210.636200 62.352116,210.938110 
	C62.059135,210.940979 61.764072,210.731827 61.238220,210.534821 
	C61.238220,180.216904 61.238220,149.829147 61.238220,119.000000 
	C65.371490,119.000000 69.326477,119.000000 73.281471,119.000000 
	C94.439461,119.000000 115.598343,118.880730 136.755127,119.040604 
	C151.650146,119.153168 166.505157,119.647408 180.780411,124.897339 
	C195.610489,130.351303 206.359451,139.355377 209.303314,155.712128 
	C209.386566,156.174683 209.737259,156.589096 209.979645,157.481171 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M696.261353,226.346329 
	C710.335083,196.087631 738.582031,186.146530 761.201050,187.294113 
	C800.507263,189.288284 823.611877,213.989090 828.582947,248.730682 
	C782.570374,248.730682 736.595093,248.730682 689.138550,248.730682 
	C691.515747,241.140732 693.781982,233.904922 696.261353,226.346329 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M1397.995117,253.908630 
	C1399.498901,239.080185 1404.621582,226.289902 1413.565308,214.789627 
	C1423.885010,201.520126 1437.505737,193.506744 1453.492065,190.411118 
	C1493.470459,182.669662 1530.654785,201.574829 1540.866943,244.502502 
	C1545.736572,264.972504 1543.827271,285.241394 1533.891113,304.027161 
	C1523.080811,324.465668 1505.858765,336.342529 1482.902222,340.063171 
	C1466.912964,342.654602 1451.475708,340.923035 1437.065308,333.838287 
	C1421.037476,325.958466 1409.603516,313.381378 1402.987427,296.593384 
	C1398.424194,285.014252 1396.701294,272.994507 1397.038696,260.634796 
	C1397.095947,258.537384 1397.660767,256.453827 1397.995117,253.908630 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M1788.000000,264.014771 
	C1788.680054,283.475494 1783.304565,300.719696 1771.649048,315.513031 
	C1760.847412,329.222717 1746.170166,337.148743 1728.884521,339.988831 
	C1717.076660,341.928894 1705.436279,341.433105 1693.843994,338.088593 
	C1675.306519,332.740295 1661.435181,321.583984 1652.049194,304.938293 
	C1644.326294,291.242096 1641.363647,276.170227 1642.127808,260.645782 
	C1643.109741,240.695404 1649.753906,222.646500 1664.245728,208.426514 
	C1674.677002,198.191193 1687.437256,192.105255 1702.090698,189.927689 
	C1717.679688,187.611099 1732.934448,188.551895 1747.216797,195.223480 
	C1759.716064,201.062103 1769.861572,210.054642 1777.034302,222.118927 
	C1784.630737,234.895950 1788.230957,248.691620 1788.000000,264.014771 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M1938.978760,517.663513 
	C1934.348511,531.932373 1925.494141,541.360413 1911.163330,545.426147 
	C1886.703369,552.365601 1860.437012,535.508545 1859.826782,508.007843 
	C1859.429810,490.113251 1871.000610,473.195648 1890.326904,468.345764 
	C1914.641479,462.244080 1940.330322,480.245697 1939.961304,506.020966 
	C1939.907715,509.764130 1939.331665,513.499878 1938.978760,517.663513 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M1006.983276,493.269653 
	C1014.406250,516.659241 1002.444336,541.698425 976.663208,546.149048 
	C962.868103,548.530518 950.866943,545.192566 940.998596,536.168823 
	C930.882996,526.918884 927.009399,514.370605 929.240356,500.874237 
	C931.973633,484.338501 941.882812,473.552246 958.225525,468.922943 
	C971.889404,465.052460 983.982910,468.014526 995.156860,476.542023 
	C1000.828979,480.870758 1003.966797,486.744720 1006.983276,493.269653 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M1742.797119,545.355347 
	C1725.657593,541.402100 1714.348633,524.742493 1713.867310,508.863068 
	C1713.444214,494.909637 1718.550293,483.635620 1729.283569,475.514709 
	C1737.780884,469.085541 1747.713867,466.146057 1758.967041,467.757812 
	C1768.442627,469.114899 1776.144165,472.726501 1783.000610,479.322327 
	C1789.074097,485.165009 1792.752808,492.280670 1793.787598,500.151886 
	C1796.628174,521.757385 1785.156616,542.423462 1761.517334,545.860413 
	C1755.523804,546.731812 1749.261230,545.753052 1742.797119,545.355347 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M197.748993,535.251465 
	C189.625839,527.412781 186.074860,517.454468 186.302368,507.246033 
	C186.729248,488.091125 198.208939,471.140717 220.809814,467.904388 
	C234.637070,465.924347 246.318344,469.720703 255.875748,479.502106 
	C266.553833,490.430511 269.248993,503.572968 265.326294,518.299927 
	C260.391998,536.824768 242.580765,548.461243 223.757385,546.904480 
	C214.022507,546.099487 205.174301,542.505981 197.748993,535.251465 
z"/>
<path fill="#FFFFFF" opacity="000000" stroke="none" 
	d="
M1083.000000,473.123138 
	C1083.070190,471.188690 1083.140503,469.740997 1083.268677,467.097870 
	C1096.043457,468.065002 1108.595215,468.204224 1120.849243,470.184967 
	C1131.771118,471.950348 1136.273438,480.217682 1136.971680,489.984131 
	C1137.733643,500.641357 1131.298950,509.803955 1121.158691,512.344727 
	C1118.034424,513.127563 1114.806519,513.853882 1111.610718,513.925720 
	C1102.306396,514.134949 1092.994141,514.000000 1083.000000,514.000000 
	C1083.000000,500.420258 1083.000000,487.015076 1083.000000,473.123138 
z"/>
</svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
