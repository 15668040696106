// routes
// components
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'About',
    icon: <Iconify icon={ 'eva:home-fill' } { ...ICON_SIZE } />,
    path: '/about-us',
  },
  {
    title: 'Our Environments',
    icon: <Iconify icon={ 'eva:home-fill' } { ...ICON_SIZE } />,
    path: '/environments',
  },
  // {
  //   title: 'Our Adults',
  //   icon: <Iconify icon={ 'eva:home-fill' } { ...ICON_SIZE } />,
  //   path: '/adults',
  // },
  {
    title: 'Admission',
    icon: <Iconify icon={ 'eva:home-fill' } { ...ICON_SIZE } />,
    path: '/admission',
  },
  // {
  //   title: 'Outreach',
  //   icon: <Iconify icon={ 'eva:home-fill' } { ...ICON_SIZE } />,
  //   path: '/outreach',
  // },
  // {
  //   title: 'Contact',
  //   icon: <Iconify icon={ 'eva:home-fill' } { ...ICON_SIZE } />,
  //   path: '/support',
  // },
];

export default menuConfig;
